<template>
    <div class="error-box">
        <i class="iconfont icon-yichang"></i>
        <div style="margin-top:2rem">{{info}}异常，已停止访问</div>
        <div style="margin-top:2rem;font-size:0.7rem">请在{{position}}中打开 或 重新访问</div>
    </div>
</template>
<script>
export default {
    name:"error",
    data(){
        return{
            info:"登录帐号",
            position:"微信"
        }
    },
    mounted(){
        if(this.$route.params.info){
            this.info = this.$route.params.info
        }
        if(this.$route.params.position){
            this.position = this.$route.params.position
        }
    }
}
</script>
<style scoped>
    .error-box{
        position: fixed;
        width: 100%;
        background: #f7f7f7;
        min-height: 100%;
        padding-top: 4rem;
        text-align: center;
        color: #000;
    }
    .error-box .icon-yichang{
        font-size: 7rem;
        color: #FF413D
    }
</style>